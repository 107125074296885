import React from "react";
import { Container } from "./styles";

interface Props {
  id?: string;
  className?: string;
}

const ContentContainer: React.FC<Props> = ({ id, className = "", children }) => {
  return (
    <Container id={id} className={className}>
      {children}
    </Container>
  );
};

export default ContentContainer;
