import { createGlobalStyle, css } from "styled-components";
import { breakpoints } from "./breakpoints";

export const GlobalStyles = createGlobalStyle`
  ${({ theme }) => css`
    ::-webkit-scrollbar {
      width: 10px;
      height: 10px;

      @media (max-width: ${breakpoints.md}) {
        width: 3px;
        height: 3px;
      }
    }

    ::-webkit-scrollbar-track {
      background: ${theme.scroll.trackColor};
    }

    ::-webkit-scrollbar-thumb {
      background: ${theme.scroll.thumbColor};

      :hover {
        background: ${theme.scroll.thumbColor}D1;
      }
    }

    a {
      :hover {
        opacity: 0.7;
      }
    }

    .underlined {
      text-decoration: underline;
    }

    .mt-20 {
      margin-top: 20px;
    }

    .mt-25 {
      margin-top: 25px;
    }

    .mt-30 {
      margin-top: 30px;
    }

    .mt-40 {
      margin-top: 40px;
    }

    .mr-10 {
      margin-right: 10px;
    }

    .mb-20 {
      margin-bottom: 20px;
    }

    .mb-30 {
      margin-bottom: 30px;
    }

    .inline {
      display: inline;
    }

    .uppercase {
      text-transform: uppercase;
    }
  `}; 
`;
