import React from "react";
import { Button as IParqueButton } from "iparque-components";
import styled from "styled-components";

interface Props {
  className?: string;
}

const Button: React.FC<Props> = ({ children, className }) => {
  return (
    <StyledButton className={className} size="xs">
      {children}
    </StyledButton>
  );
};

export default Button;

const StyledButton = styled(IParqueButton)`
  background-color: #a4d8fa;
  color: #000000;

  :hover {
    background-color: #a4d8faaa;
  }

  :focus {
    background-color: #a4d8faaa;
  }
`;
