import styled from "styled-components";
import { breakpoints } from "theme/breakpoints";

export const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 70%;
  max-width: 1344px;
  margin-left: auto;
  margin-right: auto;
  position: relative;

  @media (max-width: ${breakpoints.lg}) {
    width: 80%;
  }

  @media (max-width: ${breakpoints.sm}) {
    width: 90%;
  }
`;
