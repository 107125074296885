/* eslint-disable react/jsx-props-no-spreading */
import { ExtraPadding } from "interfaces/extraPadding";
import React from "react";
import { Container } from "./styles";

interface FullPageProps {
  className?: string;
  id?: string;
}

export interface FullPagePropsWithExtraPadding extends FullPageProps, ExtraPadding {}

const FullPageContainer: React.FC<FullPageProps> = ({ className = "", id, children }) => {
  return (
    <Container className={className} id={id}>
      {children}
    </Container>
  );
};

export default FullPageContainer;
