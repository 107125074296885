const set = (
  name: string,
  value: string | boolean | number,
  minutes: number,
  args = { sameSite: "Lax", secure: false },
): void => {
  let expiresConfig = "";
  let sameSiteConfig = "";
  let secureConfig = "";

  const { sameSite, secure } = args;

  if (minutes) {
    const date = new Date();
    date.setTime(date.getTime() + minutes * 60 * 1000);
    expiresConfig = `; expires=${date.toUTCString()}`;
  }

  if (sameSite) {
    sameSiteConfig = `; SameSite=${sameSite}`;
  }

  if (secure) {
    secureConfig = `; Secure`;
  }

  document.cookie = `${name}=${
    value || ""
  }${expiresConfig}${sameSiteConfig}${secureConfig}; path=/`;
};

const get = (name: string): string | null => {
  const nameEqual = `${name}=`;
  const cookies = document.cookie.split(";");

  for (let i = 0; i < cookies.length; i += 1) {
    let cookie = cookies[i];

    while (cookie.charAt(0) === " ") {
      cookie = cookie.substring(1, cookie.length);
    }

    if (cookie.indexOf(nameEqual) === 0) {
      return cookie.substring(nameEqual.length, cookie.length);
    }
  }

  return null;
};

const CookieHandler = {
  get,
  set,
};

export default CookieHandler;
