import useMediaQuery from "hooks/useMediaQuery";
import React from "react";

interface Props {
  minWidth?: string;
  maxWidth?: string;
  children: React.ReactNode;
}

const Responsive: React.FC<Props> = ({ minWidth, maxWidth, children }) => {
  const shouldRender = useMediaQuery({ minWidth, maxWidth });

  return shouldRender ? <>{children}</> : null;
};

export default Responsive;
