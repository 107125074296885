import React from "react";
import styled from "styled-components";
import { Heading } from "iparque-components";
import { ReactComponent as Ballon } from "./assets/ballon-small.svg";
import CloseButton from "./CloseButton";
import useTranslations from "./useTranslations";
import Button from "./Button";
import { ComponentProps, PORTAL_DE_DENUNCIAS_LINK } from "./helper";

const PortalDeDenunciasAdSmallDevices: React.FC<ComponentProps> = ({ onClose }) => {
  const translations = useTranslations();

  return (
    <Container>
      <CloseButton onClick={onClose} />
      <BallonContainer>
        <Ballon width="100%" />
        <ContentContainer>
          <ContentWrapper>
            <Text variant="h8" color="secondary">
              <b>{translations.info}</b>
              &nbsp;
              {translations.add}
            </Text>
            <a href={PORTAL_DE_DENUNCIAS_LINK} target="_blank" rel="noreferrer">
              <StyledButton>{translations.button}</StyledButton>
            </a>
          </ContentWrapper>
        </ContentContainer>
      </BallonContainer>
    </Container>
  );
};

export default PortalDeDenunciasAdSmallDevices;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

const BallonContainer = styled.div`
  width: 350px;
  position: relative;
  top: 20px;
`;

const ContentContainer = styled.div`
  position: absolute;
  top: 55px;
  width: 330px;
  left: 18px;
`;

const ContentWrapper = styled.div`
  margin: 20px 20px 5px;
`;

const Text = styled(Heading)`
  font-size: 13px;
  line-height: 16px;

  a {
    text-decoration: underline;
  }
`;

const StyledButton = styled(Button)`
  display: flex;
  margin-left: auto;
  padding: 3px 7px;
  font-size: 13px;
  margin-top: 5px;
`;
