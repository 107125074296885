import usePardot from "hooks/usePardot";
import React, { Suspense, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { setMetaDescription } from "utils/dom";
import Head from "./Head";

const IParqueModules = React.lazy(() => import("./IParqueModules"));
const Map = React.lazy(() => import("./Map"));

const LandingPage: React.FC = () => {
  usePardot();
  const { t } = useTranslation();

  useEffect(() => {
    document.title = t("10683") /* iParque – Gestão de Estacionamentos */;

    setMetaDescription(
      t("10676") /* Todos os módulos necessários para a gestão de estacionamentos. */,
    );
  }, [t]);

  return (
    <>
      <Head />
      <Suspense fallback={null}>
        <IParqueModules />
        <Map />
      </Suspense>
    </>
  );
};

export default LandingPage;
