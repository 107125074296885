import { driverPublic, driverNormalColors as colors } from "iparque-components";

const theme = {
  ...driverPublic,
  colors: {
    ...colors,
    dark: "#475563",
    driver: "#31D56B",
    onStreet: "#F1A606",
    enforcer: "#F44738",
    street: "#24CECE",
    offStreet: "#BABABA",
    sensing: "#CB0178",
  },
  scroll: {
    trackColor: colors.light,
    thumbColor: colors.darker,
  },
};

theme.button.configs.opacity = "D1";
theme.formField.color.primary.focus = colors.darker;
theme.typography.heading.color.tertiary = theme.typography.text.color.tertiary;
theme.typography.text.color.quintenary = theme.colors.darker;

export default theme;
