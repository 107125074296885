import React, { useState, useEffect } from "react";
import {
  PublicPagePopupsProvider,
  Text,
  Link,
  breakpointsInPixels,
  breakpointsMapping,
} from "iparque-components";
import { useTranslation } from "react-i18next";
import useLocalizedUrls from "hooks/useLocalizedUrls";
import useWarnings from "hooks/useWarnings";
import useCookies from "hooks/useCookies";
import useWindowSize from "hooks/useWindowSize";

const POPUPS_DELAY = 1.5;

const PagePopups: React.FC = ({ children }) => {
  const { t } = useTranslation();
  const { privacyPolicyUrl } = useLocalizedUrls();
  const warnings = useWarnings();
  const { isCookiesAccepted, handleAcceptCookies } = useCookies();
  const { width } = useWindowSize();
  const [shouldCookiesPopupPushContent, setShouldCookiesPopupPushContent] = useState(
    width <= breakpointsInPixels[breakpointsMapping.md],
  );

  useEffect(() => {
    setShouldCookiesPopupPushContent(width <= breakpointsInPixels[breakpointsMapping.md]);
  }, [width]);

  return (
    <PublicPagePopupsProvider
      popupsDelay={POPUPS_DELAY}
      warnings={warnings}
      shouldCookiesPopupPushContent={shouldCookiesPopupPushContent}
      cookies={
        isCookiesAccepted
          ? undefined
          : {
              body: (
                <>
                  <Text variant="body7" color="secondary">
                    {
                      t(
                        "7875",
                      ) /* O iParque utiliza cookies para melhorar a tua experiência neste website. */
                    }
                  </Text>
                  <Text className="inline" variant="body7" color="secondary">
                    {`${t("7953") /* Ao navegar, estás a consentir a utilização. */}
                    ${t("7954") /* Vê mais sobre a */}`}
                    &nbsp;
                    <b>
                      <Link href={privacyPolicyUrl} target="_blank">
                        {t("7955") /* política de cookies acin */}
                      </Link>
                    </b>
                    .
                  </Text>
                </>
              ),
              button: {
                label: t("6517") /* Aceito */,
                onClick: handleAcceptCookies,
              },
            }
      }
    >
      {children}
    </PublicPagePopupsProvider>
  );
};

export default PagePopups;
