import { IPARQUE_ABOUT_US } from "configs/links";
import React, { Suspense } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import LandingPage from "./LandingPage";

const Footer = React.lazy(() => import("./Footer"));
const AboutPage = React.lazy(() => import("./AboutPage"));
const Menu = React.lazy(() => import("./Menu"));

const Page: React.FC = () => {
  return (
    <BrowserRouter basename={process.env.REACT_APP_BASE_RELATIVE_ROUTES}>
      <Switch>
        <Route path="/" exact>
          <LandingPage />
        </Route>
        <Route path={IPARQUE_ABOUT_US}>
          <Suspense fallback={null}>
            <AboutPage />
          </Suspense>
        </Route>
      </Switch>
      <Suspense fallback={null}>
        <Footer />
        <Menu />
      </Suspense>
    </BrowserRouter>
  );
};

export default Page;
