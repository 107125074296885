import App from "App";
import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import "fonts.css";
import "i18n/i18n";

ReactDOM.render(
  <Suspense fallback={null}>
    <App />
  </Suspense>,
  document.getElementById("root"),
);
