import FullPageContainer, {
  FullPagePropsWithExtraPadding,
} from "components/FullPageContainer";
import { Heading } from "iparque-components";
import styled, { css, keyframes } from "styled-components";
import { breakpoints } from "theme/breakpoints";

interface AnimationProps {
  shouldAnimate: boolean;
}

interface FullPageContainerProps extends FullPagePropsWithExtraPadding, AnimationProps {}

const getCityKeyframes = (translateX: string) => keyframes`
  0% {
    transform: translate(${translateX}, 100%);
    opacity: 0;
  }

  100% {
    transform: translate(${translateX}, -10%);
    opacity: 1;
  }
`;

const getTextKeyframes = (translateX: string) => keyframes`
  0% {
    transform: translate(${translateX}, 100%);
    opacity: 0;
  }

  100% {
    transform: translate(${translateX}, 0);
    opacity: 1;
  }
`;

const getCityAnimation = (translateX: string) => css<AnimationProps>`
  ${({ shouldAnimate }) =>
    shouldAnimate
      ? css`
          animation: ${getCityKeyframes(translateX)} 1s;
          animation-fill-mode: both;
        `
      : ""}
`;

const getTextAnimation = (translateX = "0") => css<AnimationProps>`
  ${({ shouldAnimate }) =>
    shouldAnimate
      ? css`
          animation: ${getTextKeyframes(translateX)} 0.5s;
          animation-fill-mode: both;
          animation-delay: 0.7s;
        `
      : ""}
`;

export const StyledFullPageContainer = styled(FullPageContainer)<FullPageContainerProps>`
  transition: padding-top
    ${({ extraPaddingTopAnimationDuration }) => extraPaddingTopAnimationDuration || 0}s;
  padding-top: ${({ extraPaddingTop }) => extraPaddingTop || 0}px;

  .city-animation {
    width: 90%;
    margin: 0 auto;
    opacity: 0;
    ${getCityAnimation("-5%")};

    @media (max-width: ${breakpoints.xl}) {
      min-width: 1024px;
      margin-left: 50%;
      ${getCityAnimation("-50%")};
    }

    @media (max-width: ${breakpoints.sm}) {
      min-width: 600px;
    }
  }
`;

export const LogoContainer = styled.div`
  padding-top: 60px;
  padding-left: 10%;

  @media (max-width: ${breakpoints.lg}) {
    padding-left: 5%;
  }

  @media (max-width: ${breakpoints.md}) {
    padding-top: 30px;
  }
`;

export const TextContainer = styled.div`
  position: absolute;
  bottom: 100px;
  right: 0;
  max-width: 600px;
  opacity: 0;
  ${getTextAnimation()};

  @media (min-width: ${breakpoints.lg}) {
    ${getTextAnimation("20%")};
  }

  @media (max-width: ${breakpoints.lg}) {
    left: 0;
    bottom: 10%;
  }
`;

export const StyledHeading = styled(Heading)`
  line-height: 1em;
`;

export const StyledSubHeading = styled(Heading)`
  font-family: "Lato";
  font-weight: ${({ theme }) => theme.typography.weight.regular};
  font-size: 1.11rem;
`;

export const AdContainer = styled.div`
  display: flex;
  margin-bottom: 40px;
`;
