import { useCallback } from "react";
import CookieHandler from "utils/cookies";

const iParqueCookiesAcceptanceName = "IPCookies";
const cookieDurationInMinutes = 525600; // 365 days

const useCookies = () => {
  const handleAcceptCookies = useCallback(() => {
    CookieHandler.set(iParqueCookiesAcceptanceName, true, cookieDurationInMinutes);
  }, []);

  return {
    isCookiesAccepted: CookieHandler.get(iParqueCookiesAcceptanceName),
    handleAcceptCookies,
  };
};

export default useCookies;
