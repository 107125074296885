import { useEffect, useMemo, useState } from "react";

interface Params {
  minWidth?: string;
  maxWidth?: string;
}

const useMediaQuery = ({ minWidth, maxWidth }: Params): boolean => {
  const mediaQuery = useMemo(() => {
    const minWidthQuery = minWidth && `(min-width: calc(${minWidth} + 1px))`;
    const maxWidthQuery = maxWidth && `(max-width: ${maxWidth})`;

    return [minWidthQuery, maxWidthQuery].filter((query) => query).join(" and ");
  }, [maxWidth, minWidth]);

  const mediaQueryList = useMemo(() => window.matchMedia(mediaQuery), [mediaQuery]);

  const [matches, setMatches] = useState(mediaQueryList.matches);

  useEffect(() => {
    const mediaQueryHandler = (event: MediaQueryListEvent) => {
      setMatches(event.matches);
    };

    mediaQueryList.addEventListener("change", mediaQueryHandler);

    return () => {
      mediaQueryList.removeEventListener("change", mediaQueryHandler);
    };
  }, [mediaQueryList]);

  return matches;
};

export default useMediaQuery;
