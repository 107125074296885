import {
  ACIN_PRIVACY_POLICY_URL,
  ACIN_SECURITY_POLICY_URL,
  IPARQUE_GENERAL_CONDITIONS,
  LANGUAGE_PLACEHOLDER,
} from "configs/links";
import { useMemo } from "react";
import useLanguage from "./useLanguage";

const useLocalizedUrls = () => {
  const language = useLanguage();

  return useMemo(
    () => ({
      privacyPolicyUrl: ACIN_PRIVACY_POLICY_URL.replace(LANGUAGE_PLACEHOLDER, language),
      securityPolicyUrl: ACIN_SECURITY_POLICY_URL.replace(LANGUAGE_PLACEHOLDER, language),
      termsAndConditionsUrl: IPARQUE_GENERAL_CONDITIONS.replace(
        LANGUAGE_PLACEHOLDER,
        language,
      ),
    }),
    [language],
  );
};

export default useLocalizedUrls;
