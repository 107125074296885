import { useMemo } from "react";
import { useTranslation } from "react-i18next";

const useLanguage = () => {
  const { i18n } = useTranslation();

  return useMemo(() => i18n.language.slice(0, 2), [i18n.language]);
};

export default useLanguage;
