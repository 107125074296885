import PagePopups from "components/PagePopups";
import PublicPage from "domain/public";
import { GlobalStyles as IParqueGlobalStyles } from "iparque-components";
import React from "react";
import { ThemeProvider } from "styled-components";
import defaultTheme from "theme/defaultTheme";
import { GlobalStyles } from "theme/globalStyles";

const App: React.FC = () => {
  return (
    <ThemeProvider theme={defaultTheme}>
      <GlobalStyles />
      <IParqueGlobalStyles />
      <PagePopups>
        <PublicPage />
      </PagePopups>
    </ThemeProvider>
  );
};

export default App;
