import Responsive from "components/Responsive";
import React from "react";
import { breakpoints } from "theme/breakpoints";
import { ComponentProps } from "./helper";
import PortalDeDenunciasAdLargeDevices from "./LargeDevices";
import PortalDeDenunciasAdSmallDevices from "./SmallDevices";

const PortalDeDenunciasAd: React.FC<ComponentProps> = ({ onClose }) => {
  return (
    <>
      <Responsive minWidth={breakpoints.lg}>
        <PortalDeDenunciasAdLargeDevices onClose={onClose} />
      </Responsive>
      <Responsive maxWidth={breakpoints.lg} minWidth={breakpoints.xs}>
        <PortalDeDenunciasAdSmallDevices onClose={onClose} />
      </Responsive>
    </>
  );
};

export default PortalDeDenunciasAd;
