export const LANGUAGE_PLACEHOLDER = "LANGUAGE_PLACEHOLDER";

export const ACIN = "https://www.acin.pt/";
export const ACIN_PRIVACY_POLICY_URL = `https://www.acin.pt/${LANGUAGE_PLACEHOLDER}/politica-privacidade/`;
export const ACIN_SECURITY_POLICY_URL = `https://acin.pt/documents/PL03-Information Security Policy.${LANGUAGE_PLACEHOLDER}.pdf`;
export const IPARQUE_GENERAL_CONDITIONS = `https://www.iparque.pt/iparque/doc/${LANGUAGE_PLACEHOLDER}/condicoes_gerais_adesao.pdf`;
export const ACIN_PORTO_LOCATION =
  "https://www.google.pt/maps/place/ACIN+-+iCloud+Solutions+-+Porto/@41.2587977,-8.6435667,17z/data=!3m1!4b1!4m5!3m4!1s0xd2467e8569a452f:0xfb5ca1114f938fda!8m2!3d41.2587937!4d-8.641378";
export const ACIN_LISBON_LOCATION =
  "https://www.google.com/maps/place/ACIN+-+iCloud+Solutions+-+Lisboa/@38.7446805,-9.1487847,17z/data=!3m1!4b1!4m5!3m4!1s0xd1931fa7f89f40b:0xde35c286b3855019!8m2!3d38.7446763!4d-9.146596";
export const ACIN_MADEIRA_LOCATION =
  "https://www.google.pt/maps/place/ACIN+-+iCloud+Solutions/@32.679014,-17.0621327,17z/data=!3m1!4b1!4m5!3m4!1s0xc6059a3d538596b:0xa393204f0d195615!8m2!3d32.679014!4d-17.059944";
export const ACIN_AZORES_LOCATION =
  "https://www.google.pt/maps/place/ACIN+-+iCloud+Solutions+-+A%C3%A7ores/@37.736978,-25.6785327,17z/data=!3m1!4b1!4m5!3m4!1s0xb432bb9e38b6363:0x4f9bff9f6e7e0837!8m2!3d37.736978!4d-25.676344";
export const COMPLAINTS_BOOK = "https://www.livroreclamacoes.pt";
export const IPARQUE_LINKEDIN =
  "https://www.linkedin.com/showcase/iparque-smart-parking/";
export const IPARQUE_YOUTUBE = "https://www.youtube.com/user/iParqueMS";
export const IPARQUE_DRIVER = <string>process.env.REACT_APP_IPARQUE_DRIVER_URL;
export const IPARQUE_ONSTREET = <string>process.env.REACT_APP_IPARQUE_ONSTREET_URL;
export const IPARQUE_ENFORCER = <string>process.env.REACT_APP_IPARQUE_ENFORCER_URL;
export const IPARQUE_STREET = <string>process.env.REACT_APP_IPARQUE_STREET_URL;
export const IPARQUE_OFFSTREET = <string>process.env.REACT_APP_IPARQUE_OFFSTREET_URL;
export const IPARQUE_SENSING = <string>process.env.REACT_APP_IPARQUE_SENSING_URL;
export const IPARQUE_ABOUT_US = "/about";
export const IPARQUE_MODULES = "/#modules";
export const IPARQUE_ENFORCER_APP_STORE =
  "https://play.google.com/store/apps/details?id=com.acin.iparque.enforcer";
export const CERTIFICATES_DIV_ID = "certificates-area";
export const CERTIFICATES_URL = `${process.env.PUBLIC_URL}${IPARQUE_ABOUT_US}/#${CERTIFICATES_DIV_ID}`;
