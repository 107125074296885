import React from "react";
import styled from "styled-components";
import { ReactComponent as CloseIcon } from "assets/svg/close.svg";

interface Props {
  onClick: () => void;
}

const CloseButton: React.FC<Props> = ({ onClick }) => {
  return (
    <Container onClick={onClick}>
      <CloseIcon fill="#001531" width={14} />
    </Container>
  );
};

export default CloseButton;

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  background-color: #c0e6f9;
  cursor: pointer;

  :hover {
    background-color: #9fd8f5;
  }
`;
