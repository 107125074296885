import useLanguage from "hooks/useLanguage";
import React from "react";

const LINK = "https://dre.pt/dre/detalhe/lei/93-2021-176147929?_ts=1680098237523";

const translations = {
  pt: {
    info: (
      <>
        De acordo com a{" "}
        <a href={LINK} target="_blank" rel="noreferrer">
          Lei n.º 93/2021
        </a>
        , as empresas com mais de 50 colaboradores e as autarquias locais com mais de 10
        mil habitantes devem dispor de um canal de denúncias.
      </>
    ),
    add: "Adira ao Portal de Denúncias e evite contraordenações.",
    button: "Saiba mais",
  },
  es: {
    info: (
      <>
        De conformidad con la{" "}
        <a href={LINK} target="_blank" rel="noreferrer">
          Ley 93/2021
        </a>
        , las empresas con más de 50 empleados y las Administraciones locales con más de
        10.000 habitantes deben disponer de un canal de denuncias.
      </>
    ),
    add: "Únase a Portal de Denúncias y evite las infracciones.",
    button: "Más información",
  },
  en: {
    info: (
      <>
        In accordance with{" "}
        <a href={LINK} target="_blank" rel="noreferrer">
          Law 93/2021
        </a>
        , companies with more than 50 employees and local administrations with more than
        10,000 inhabitants must have a reporting channel.
      </>
    ),
    add: "Join Portal de Denúncias and avoid violations.",
    button: "Learn more",
  },
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const useTranslations = () => {
  const language = useLanguage();

  return translations[language as keyof typeof translations];
};

export default useTranslations;
