/* eslint-disable no-empty */
import { Warning } from "interfaces/warnings";
import { useEffect, useState } from "react";
import { getFromStorage, saveOnStorage } from "utils/localStorage";
import useLanguage from "./useLanguage";

const localStorageKey = "c8bc87b4-ad15-4177-b87a-8e9e77e575e5";

interface RowWarning {
  id?: string;
  body: string;
  button?: string;
  from?: string;
  to?: string;
}

const useWarnings = () => {
  const [warnings, setWarnings] = useState<Array<Warning>>([]);
  const language = useLanguage();

  useEffect(() => {
    (async () => {
      try {
        const response = await fetch(
          `${process.env.PUBLIC_URL}/locales/${language}/warnings.json`,
        );

        const warningsResponse: Array<RowWarning> = await response.json();

        const newWarnings: Array<Warning> = [];

        warningsResponse.forEach((warning, index) => {
          const warningLocalStorageKey = `${localStorageKey}-${warning.id || index}`;

          if (getFromStorage(warningLocalStorageKey)) {
            return;
          }

          const warningCopy: Warning = {
            ...warning,
            button: warning.button
              ? {
                  label: warning.button,
                  onClick: () => saveOnStorage(warningLocalStorageKey, true),
                }
              : undefined,
            id: index,
          };

          newWarnings.push(warningCopy);
        });

        setWarnings(newWarnings);
      } catch {
        setWarnings([]);
      }
    })();
  }, [language]);

  return warnings;
};

export default useWarnings;
